//
// Aside
//

// General Mode(all devices)
.aside {
	display: flex;
	box-shadow: get($aside-config, base, box-shadow);

	// Aside Primary Panel
	.aside-primary {
		width: get($aside-config, base, primary-width);
		flex-shrink: 0;
	}

	// Aside Secondary Panel
	.aside-secondary {
		overflow: hidden;

		// Workspace
		.aside-workspace {
			width: get($aside-config, base, secondary-width);
			flex-shrink: 0;
		}
	}

	// Aside Nav
	.aside-primary {
		.btn-custom {
			// $color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active
			@include button-custom-variant(null, null, null, null, $white, $white, null, rgba($white, 0.05));
		}
	}
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	.aside {
		transition: width get($aside-config, base, transition-speed) ease;

		// Fixed Aside Mode
		.aside-fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: get($aside-config, base, z-index);
		}

		// Static Aside Mode
		.aside-static & {
			position: relative;
			z-index: 1;
		}

		// Aside secondary panel enabled
		.aside-secondary-enabled & {
			width: get($aside-config, base, primary-width) + get($aside-config, base, secondary-width);
		}

		// Aside primary panel disabled and aside secondary enabled modes
		.aside-primary-disabled.aside-secondary-enabled & {
			width: get($aside-config, base, secondary-width);
		}

		// Aside Secondary Panel disabled
		.aside-secondary-disabled & {
			width: get($aside-config, base, primary-width);
		}

		// Minimized Aside Mode
		[data-kt-aside-minimize="on"] & {
			width: get($aside-config, base, primary-width);
			transition: get($aside-config, base, transition);

			// Aside Primary Panel
			.aside-primary {
				border-color: transparent;
			}
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(lg) {
	.aside {
		display: none;

		// Aside Primary Panel
		.aside-primary {
			width: get($aside-config, base, primary-width-tablet-and-mobile);

			// Buttons
			.btn.btn-icon.btn-lg {
				width: 40px;
				height: 40px;

				.svg-icon {
					@include svg-icon-size(26px);
				}
			}
		}

		// Aside Secondary Panel
		.aside-secondary {
			// Workspace
			.aside-workspace {
				width: 100%;
				flex-shrink: 1;
			}
		}
	}
}
