//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary:       									#8159fd;
$primary-active:    								#5f32ed;
$primary-light:    									#F6F1FC;
$primary-inverse:  									#FFFFFF;

// Success
$success:       									#20D489;
$success-active:    								#19B674;
$success-light:    									#E4FFF4;
$success-inverse:  									#FFFFFF;

// Info
$info:       									    #146ced;
$info-active:    									#0e59c8;
$info-light:    									#f1f4ff;
$info-inverse:  									#FFFFFF;

// Danger
$danger:       									    #F1416C;
$danger-active:    									#D9214E;
$danger-light:    									#FFEFF3; 
$danger-inverse:  									#FFFFFF;

// Warning
$warning:       									#FFC700;
$warning-active:    								#F1BC00;
$warning-light:    									#FFF8DD;
$warning-inverse:  									#FFFFFF;

// Border Radiues
$border-radius-sm:            						.3rem;
$border-radius:               						.65rem;
$border-radius-lg:            						.85rem;
$border-radius-xl:                                  1.25rem;

// Card Box Shadow
$card-box-shadow:                                   0px 0px 20px 0px rgba(76,87,125,0.02);
